import React, { useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import { useNavigationContext } from '@/hooks/useNavigationContext'
import { useAuth } from '@/providers/Auth'
import { Header, SidebarNavigationContext } from '@/organisms/index'
import { Breadcrumb } from '@/molecules/index'
import { Sidebar } from '@/molecules/index'
import { cn } from '@/lib/utils'

type Props = {
  children: React.ReactNode
  fullWidth?: boolean
  className?: string
}

const NavigationContextTemplate: React.FC<Props> = ({
  children,
  fullWidth = false,
  className
}) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const [ready, setReady] = useState(false)

  const router = useRouter()
  const redirected = useRef() as any
  const { pathname } = useRouter()

  const { userContext, isLogged, refreshTokenWithValidator, isAdmin } =
    useAuth()
  const { navigation, breadcrumbs } = useNavigationContext(pathname)

  useEffect(() => {
    setReady(true)
  }, [])

  useEffect(() => {
    refreshTokenWithValidator()
  }, [refreshTokenWithValidator])

  useEffect(() => {
    if (ready && !redirected.current && !isLogged) {
      router.push('/logout')
      redirected.current = true
    }
    if (router.pathname === '/login' && isLogged) {
      router.push('/')
    }
    if (router.pathname === '/users' && isAdmin === false) {
      router.push('/')
    }
  }, [ready, router, isLogged, userContext, isAdmin])

  if (!ready || !isLogged) {
    return null
  }

  return (
    <div>
      <Sidebar isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen} />
      <div>
        <Header setSidebarOpen={setIsSidebarOpen} />
        <div className="bg-gray-50 flex flex-col min-h-[calc(100vh-64px)]">
          <Breadcrumb items={breadcrumbs} showSkeleton={!ready} />
          <div className="lg:flex lg:gap-x-2 container-main">
            <SidebarNavigationContext items={navigation} />
            <main
              className={cn(
                'pt-4 pb-2 md:pt-6',
                fullWidth
                  ? 'lg:flex-auto flex-1 sm:p-6 md:p-8'
                  : 'sm:p-6 md:p-8',
                className
              )}
            >
              {children}
            </main>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NavigationContextTemplate
