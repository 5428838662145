import React from 'react'
import { Breadcrumb, BreadcrumbItem } from '@/molecules/index'

type Props = {
  header: React.ReactNode
  children?: React.ReactNode
  breadcrumbs?: BreadcrumbItem[]
  showSkeleton?: boolean
}

const DataTableTemplate: React.FC<Props> = ({
  children,
  header,
  breadcrumbs = [],
  showSkeleton = false
}) => {
  return (
    <div className="bg-gray-50 flex flex-col min-h-[calc(100vh-64px)]">
      {breadcrumbs.length > 0 && (
        <Breadcrumb items={breadcrumbs} showSkeleton={showSkeleton} />
      )}
      <div className="flex flex-col container-main">
        <div className="pt-4 pb-2 md:pt-6">{header}</div>
        <div className="flex flex-col flex-1 gap-6 p-2 my-6 bg-white rounded-lg shadow dark:bg-gray-100 dark:text-black lg:p-6">
          {children}
        </div>
      </div>
    </div>
  )
}

export default DataTableTemplate
